import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
  PageHeader,
  TechnologyPartners,
  BrandsWorkedWithUs,
  GetInTouch,
} from '@components';
import { BetterThanOther, MultiColumnList } from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class Enterprise extends Component {
  render() {
    const enterprise = pageContent.subPage.enterprise;
    return (
      <Layout>
        <PageHeader image={enterprise.headerImage} />

        <Heading
          title={enterprise.title}
          overview={enterprise.overview}
          noHeadingIcon={true}
        />

        <BetterThanOther content={enterprise.betterThanOther} />

        <MultiColumnList content={enterprise.features} />

        <MultiColumnList content={enterprise.solutions} />

        <PageNavigation
          prev={pageContent.navigation.prev}
          next={pageContent.navigation.next}
        />

        <TechnologyPartners />

        <BrandsWorkedWithUs />
      </Layout>
    );
  }
}

export default Enterprise;
